import React from 'react';
import { Frame, Page } from '@shopify/polaris';

import { CartDisplay } from '../components/CartDisplay';

import { CartContextProvider } from '../context-providers/CartContext';

export default function ({ gitCommit }) {
    console.log({ gitCommit });
    return (
        <Frame>
            <div style={{ padding: '0 2rem' }}>
                <Page title="Add Engraving (Celigo)">
                    <CartContextProvider>
                        <CartDisplay />
                    </CartContextProvider>
                </Page>
            </div>
        </Frame>
    );
}
