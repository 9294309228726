import { createContext, useState, useEffect } from 'react';
import { Cart, Group } from '@shopify/app-bridge/actions';
import Api from '../helpers/api';

import * as Sentry from '@sentry/react';

export const CartContext = createContext({
    cartStatus: null,
    lineItems: null,
    cart: null,
});

export const CartContextProvider = ({ children }) => {
    const [firstLoad, setFirstLoad] = useState(true);
    const [cartStatus, setCartStatus] = useState(null);
    const [lineItems, setLineItems] = useState(null);
    const [cart, setCart] = useState(null);

    const personalizationIdPropertyName = 'Personalization ID';

    const [engravingChargeProduct, setEngravingChargeProduct] = useState(null);

    useEffect(() => {
        doSetup();
    }, []);

    const doSetup = async () => {
        validateAvailableFeatures().then((cartValidationStatus) => {
            setCartStatus(cartValidationStatus);
        });

        Api.getEngravingChargeProduct().then((response) => {
            setEngravingChargeProduct(response.data);
        });
    };

    useEffect(() => {
        if (engravingChargeProduct != null && cartStatus == 'available') {
            subscribeToCartUpdates().then(() => {
                setFirstLoad(false);
                setCartStatus('ready');
            });
        }
    }, [cartStatus, engravingChargeProduct]);

    const validateAvailableFeatures = () => {
        return new Promise((resolve, reject) => {
            window.app
                .featuresAvailable(Group.Cart)
                .then((state) => {
                    window.app.error((data) => {
                        console.error('[client] Error received: ', data);
                        Sentry.captureException(data);
                        reject('access-error');
                    });

                    var _ref =
                            state.Cart &&
                            state.Cart[Cart.Action.FETCH] &&
                            state.Cart[Cart.Action.SET_LINE_ITEM_PROPERTIES],
                        Dispatch = _ref.Dispatch;

                    let cartIsAvailable = false;
                    if (Dispatch) {
                        cartIsAvailable = true;
                    }

                    resolve(cartIsAvailable ? 'available' : 'unavailable');
                })
                .catch((error) => {
                    console.error('[client] General error: ', error);
                    Sentry.captureException(error);
                    reject('access-error');
                });
        });
    };

    const subscribeToCartUpdates = () => {
        return new Promise((resolve, reject) => {
            const cart = Cart.create(app);
            setCart(cart);

            let unsubscriber = cart.subscribe(Cart.Action.UPDATE, function (payload) {
                // Sentry.setContext('cart update payload', { payload });
                // Sentry.captureMessage('Cart updated');

                const cartData = Object.keys(payload?.data?.lineItems || {}).length ? payload.data.lineItems : null;
                if (cartData) {
                    setLineItems(cartData);
                } else {
                    setLineItems([]);
                }

                if (firstLoad) {
                    // After the first load, this won't be running in the Promise
                    resolve();
                }

                unsubscriber();
            });
            cart.dispatch(Cart.Action.FETCH);
        });
    };

    const trackerLinkPropertyName = (trackedItem, index) => {
        return trackedItem.title + ' (Line Item Number-' + (index + 1) + ')';
    };

    const getPersonalizationIdProperty = (lineItem) => {
        if (!lineItem.properties || !lineItem.properties.length) {
            return false;
        }
        return lineItem.properties.find((property) => property.name == personalizationIdPropertyName);
    };

    const removeLineItems = (lineItemList) => {
        if (lineItems) {
            // Because we're removing based on index in line items, we need to remove them from the bottom up.
            let removalIndeces = lineItemList.map((lineItem) => {
                return lineItems.indexOf(lineItem);
            });
            removalIndeces.sort();
            removalIndeces.reverse();
            removalIndeces.forEach((index) => {
                cart.dispatch(Cart.Action.REMOVE_LINE_ITEM, {
                    index: index,
                });
            });
        }
    };

    const getCaseInsensitivePropertyValue = (itemProperties, keyString) => {
        let matchingProperty = itemProperties.find(
            (property) => property.name.toLowerCase() == keyString.toLowerCase(),
        );
        if (matchingProperty !== undefined) {
            return matchingProperty.value;
        }
        return null;
    };

    const getCorrectFeeVariantForItem = (item) => {
        console.log('getCorrectFeeVariantForItem', getCaseInsensitivePropertyValue(item.properties, 'engraving type'));
        return engravingChargeProduct.variants.find((feeVariant) => {
            // Make sure the fee and the line item either both have "set of 2", or neither do.
            if (item.title.toLowerCase().includes('set of 2') || item.title.toLowerCase().includes('set of two')) {
                if (
                    !feeVariant.title.toLowerCase().includes('set of 2') &&
                    !feeVariant.title.toLowerCase().includes('set of two')
                ) {
                    return false;
                }
            } else {
                if (
                    feeVariant.title.toLowerCase().includes('set of 2') ||
                    feeVariant.title.toLowerCase().includes('set of two')
                ) {
                    return false;
                }
            }

            // Now compare engraving types...
            let engravingType = feeVariant.title
                .toLowerCase()
                .replace(/\s*\(set of (2|two)\)/gi, '')
                .trim();
            // This should just be "standard", "initials", "monogram"

            // Hopefully the engraving type property will have one of those.
            if (
                getCaseInsensitivePropertyValue(item.properties, 'engraving type').toLowerCase().includes(engravingType)
            ) {
                return true;
            }
        });
    };

    // Make sure customised items have a corresponding personalization item, to match Zepto's behaviour
    useEffect(() => {
        // The broad approach is: if a product line item has ANY properties, we will create/update a tracker line item that points back to it, and move all the properties across.
        // The tracker item also needs a property like "Product Name (Line Item Number-1)" (see trackerLinkPropertyName()) which is how the python dax/shopify integration links them up.
        // However, while we're in a cart being edited, we can't set that and forget it, so the LineItemPropertiesSetter gives the items a unique 'Personalization ID' property to keep them linked.

        if (lineItems) {
            let productLineItems = lineItems.filter((lineItem) => {
                return lineItem.productId && lineItem.productId != engravingChargeProduct.id;
            });

            // First up: Delete all non-product items that have a 'Personalization ID' - we need to be able to update them with other data than just the quantity.
            let staleTrackerItems = lineItems.filter((lineItem) => {
                if (lineItem.productId && lineItem.productId != engravingChargeProduct.id) {
                    // Only trackers
                    return false;
                }

                let personalizationIdProperty = getPersonalizationIdProperty(lineItem);
                if (personalizationIdProperty == undefined) {
                    // Only trackers with personalization IDs
                    return false;
                }

                return true;
            });

            let timer = 1;
            if (staleTrackerItems.length) {
                removeLineItems(staleTrackerItems);
                timer = 500;
            }

            // It doesn't delete them fast enough...
            window.setTimeout(() => {
                productLineItems.forEach((lineItem, index) => {
                    if (lineItem.properties && lineItem.properties.length) {
                        let personalizationIdProperty = getPersonalizationIdProperty(lineItem);

                        if (personalizationIdProperty) {
                            console.log('personalization property found');

                            let engravingChargeVariant = getCorrectFeeVariantForItem(lineItem);

                            if (engravingChargeVariant) {
                                console.log('creating trackerData');
                                let trackerData = {
                                    variantId: engravingChargeVariant.id,
                                    quantity: lineItem.quantity,
                                    properties: [
                                        ...lineItem.properties,
                                        {
                                            name: '_Customization For',
                                            value: trackerLinkPropertyName(lineItem, index), // Note that this is the index *among line items that are products* - and that we rewrite it every time in case of index changes!
                                        },
                                    ],
                                };

                                console.log('Trying to create new');
                                cart.dispatch(Cart.Action.ADD_LINE_ITEM, {
                                    data: trackerData,
                                });
                            } else {
                                console.error(
                                    'No engraving charge variant found for properties ' +
                                        JSON.stringify(lineItem.properties),
                                );
                                let error = new Error(
                                    'No engraving charge variant found for properties ' +
                                        JSON.stringify(lineItem.properties),
                                );
                                Sentry.captureException(error);
                            }
                        } else {
                            console.log('NO personalization property found');
                        }
                    }
                });
            }, timer);
        }
    }, [lineItems]);

    return (
        <CartContext.Provider
            value={{
                cartStatus,
                lineItems,
                cart,
                engravingChargeProduct,
            }}
        >
            {children}
        </CartContext.Provider>
    );
};
